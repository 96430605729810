var app = angular.module('myApp', ['ui.router','ngCookies','pascalprecht.translate','ngSanitize','angular.filter']);

var translationsEn = '';
var translationsMr = '';

app.run(['$rootScope', '$location', 'userModel', '$cookies','$state','$stateParams','schemeModel',function ($rootScope, $location, userModel, $cookies,$state,$stateParams,schemeModel){
       $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams){
           
        if (toState.data.authenticated && !userModel.getAuthStatus()){
            $location.path('/home');
        }
    });
}]);

app.factory('Excel',function($window){
        //alert("gbjhg");
        var uri='data:application/vnd.ms-excel;base64,',
            template='<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
            base64=function(s){return $window.btoa(unescape(encodeURIComponent(s)));},
            format=function(s,c){return s.replace(/{(\w+)}/g,function(m,p){return c[p];})};
        return {
            tableToExcel:function(tableId,worksheetName){
                var table=$(tableId),
                    ctx={worksheet:worksheetName,table:table.html()},
                    href=uri+base64(format(template,ctx));
                return href;
            }
        };
    });
app.config(function($stateProvider, $urlRouterProvider,$translateProvider, masterModelProvider) {

        masterModelProvider.$get().getLabels().success(function (response) {
        //console.log(response); return;
        translationsEn = response[0];
        translationsMr = response[1];

        //Multilangual
        
         $translateProvider.translations('en', translationsEn);
         $translateProvider.translations('mr', translationsMr);

          var key = localStorage.getItem('language_key');

        if(key == 'mr'){
            //alert('hi');
            $translateProvider.use('mr');
            //$translateProvider.preferredLanguage('mr');

        }
        else if(key == 'en'){
            $translateProvider.use('en');
        }
        else{
           $translateProvider.use('en'); 
        }

        //end Multilangual

    });

    $urlRouterProvider.otherwise('/home');

    $stateProvider
        .state('home', {
            url: '/home',
            templateUrl: 'public/templates/frontend/pages/home.html',
            controller: 'homeController',
            resolve: {
                    news: function(pagesModel){
                        return pagesModel.getNews();
                    },
                    policy: function(pagesModel){
                        return pagesModel.getPolicy();    
                    },
                    notification: function(pagesModel){
                        //console.log('abc',pagesModel);
                        return pagesModel.getNotification();    
                    },
                    notice: function(pagesModel){
                        //console.log('abc',pagesModel);
                        return pagesModel.getNotice();    
                    },
                },
                   
            data:{
                authenticated: false,
            }
        })

        .state('productlist', {
            url: '/productlist',
            templateUrl: 'public/templates/frontend/pages/productlist.html',
            controller: 'globalController',
            data:{
                authenticated: false,
                 }
        })

        .state('product_details_view', {
             url: '/product_details_view/:product_id',
            templateUrl: 'public/templates/frontend/users/product_details_view.html',
            controller: 'globalController',
            data:{
                authenticated: false,
                }
        })

        .state('message', {
            url: '/message',
            templateUrl: 'public/templates/frontend/pages/message.html',
            controller: 'homeController',
            data:{
                authenticated: false,
                 }
        })

        .state('about', {
            url: '/about',
            templateUrl: 'public/templates/frontend/pages/about.html',
            controller: 'homeController',
            data:{
                authenticated: false,
                 }
        })

        .state('successstory', {
            url: '/successstory',
            templateUrl: 'public/templates/frontend/pages/successstory.html',
            controller: 'homeController',
            data:{
                authenticated: false,
                 }
        })

        .state('rti', {
            url: '/rti',
            templateUrl: 'public/templates/frontend/pages/rti.html',
            controller: 'homeController',
            data:{
                authenticated: false,
                 }
        })

        .state('grievances', {
            url: '/grievances',
            templateUrl: 'public/templates/frontend/pages/grievances.html',
            controller: 'globalController',
            data:{
                authenticated: false,
                 }
        })

        .state('contact_us',{

            url: '/contact_us',
            templateUrl: 'public/templates/frontend/pages/contact_us.html',
            //controller: 'globalController',
            data:{
                authenticated: false,
                }
        })
        .state('videos',{

            url: '/videos',
            templateUrl: 'public/templates/frontend/pages/videos.html',
            //controller: 'globalController',
            data:{
                authenticated: false,
                }
        })
        .state('self_employment_policy',{
            url: '/self_employment_policy',
            templateUrl: 'public/templates/frontend/pages/self_employment_policy.html',
            data:{
                authenticated: false,
                }
        })

        .state('economic_development_corporation',{
            url: '/economic_development_corporation',
            templateUrl: 'public/templates/frontend/pages/economic_development_corporation.html',
            data:{
                authenticated: false,
                }
        })

        .state('dashboard', {
            url: '/dashboard',
            templateUrl: 'public/templates/frontend/users/dashboard.html',
            controller: 'userController',
            data:{
                authenticated: true,
                }
        })

        .state('edit_applicationIR',{
            url: '/edit_applicationIR/:app_no',
            templateUrl: 'public/templates/frontend/scheme/edit_applicationIR2.html',
            controller: 'globalController',
            data:{
                authenticated: true,
                }
        })

        .state('edit_nominal_application',{
            url: '/edit_nominal_application/:app_no',
            templateUrl: 'public/templates/frontend/scheme/edit_nominal_application.html',
            controller: 'globalController',
            data:{
                authenticated: true,
                }
        })

        .state('edit_product_details',{
            url: '/edit_product_details/:product_id',
            templateUrl: 'public/templates/frontend/users/edit_product_details.html',
            controller: 'globalController',
            data:{
                authenticated: true,
                }
        })

        .state('sanctioned_letter', {
            url: '/sanctioned_letter/:bene_id/:app_no',
            templateUrl: 'public/templates/frontend/pages/sanctioned_letter.html',
            controller: 'userController',
            data:{
                authenticated: true,
                }
        })

        .state('registration', {
            url: '/registration',
            templateUrl: 'public/templates/frontend/users/aadhar_auth_registration1.html',
            controller: 'registrationAadharAuthController',
            data:{
                authenticated: false,
            }
        })

        .state('pre_user_aadhar',{

            url: '/aadhar-verification',
            templateUrl: 'public/templates/frontend/users/aadhar_verification.html',
            controller: 'preAadharController',
            data:{
                authenticated: false,
                }
        })

        .state('mobile_verification',{

            url: '/mobile-verification',
            templateUrl: 'public/templates/frontend/users/mobile_verification.html',
            controller: 'preAadharController',
            data:{
                authenticated: false,
                }
        })


        .state('schemes',{

            url: '/scheme/:id',
            templateUrl: 'public/templates/frontend/scheme/scheme.html',
            controller: 'globalController',
            data:{
                authenticated: false,
                }
        })

        .state('resetpassword',{
            url: '/resetpassword',
            templateUrl: 'public/templates/frontend/users/setpassword.html',
            controller: 'preAadharController',
            data:{
                authenticated: false,
                }                                
        })

        .state('changepassword',{
            url: '/changepassword',
            templateUrl: 'public/templates/frontend/users/changepassword.html',
            controller: 'globalController',
            data:{
                authenticated: false,
                }                                
        })

        .state('schemeApply',{
            url: '/scheme_apply/:scheme_id/:fund_id/:district_name/:district_id',
            templateUrl: 'public/templates/frontend/scheme/scheme_application.html',
            controller: 'globalController',
            data:{
                authenticated: true,
            }
        })

        .state('schemeApplyGL1',{
            url: '/scheme_applyGl/:scheme_id/:fund_id/:district_name/:district_id',
            templateUrl: 'public/templates/frontend/scheme/scheme_applicationGL.html',
            controller: 'globalController',
            data:{
                authenticated: true,
            }
        })

        .state('LegalDocument',{
            url: '/legal_document/:scheme_id/:fund_id/:district_name/:district_id',
            templateUrl: 'public/templates/frontend/scheme/legal_document.html',
            controller: 'globalController',
            data:{
                authenticated: true,
            }
        })

        .state('password',{

            url: '/passwordchange',
            templateUrl: 'public/templates/frontend/users/changepassword.html',
            controller: 'userController',
            data:{
                authenticated: true,
                }
        })

        .state('product',{

            url: '/productadd',
            templateUrl: 'public/templates/frontend/users/addproduct.html',
            controller: 'userController',
            data:{
                authenticated: true,
                }
        })

        .state('profile',{

            url: '/myprofile',
            templateUrl: 'public/templates/frontend/users/profile.html',
            controller: 'userController',
            data:{
                authenticated: true,
                }
        })



        .state('edit_profile',{

            url: '/edit_profile',
            templateUrl: 'public/templates/frontend/users/edit_profile.html',
            controller: 'userController',
            data:{
                authenticated: true,
                }
        })
       
        .state('candidate_applications',{

            url: '/candidate_application/:app_no',
            templateUrl: 'public/templates/frontend/users/candidate_application.html',
            controller: 'globalController',
            data:{
                authenticated: true,
                }
        })
         /*.state('revalidate_loi',{

            url: '/revalidate_loi/:app_no',
            templateUrl: 'public/templates/frontend/users/revalidate_loi.html',
            controller: 'userController',
            data:{
                authenticated: true,
                }
        })*/
         .state('pages', {
            url: '/pages/:pagesname',
            templateUrl: 'public/templates/frontend/pages/pages.html',
            controller: 'pagesController',
            data:{
                authenticated: false,
            }
        })
         .state('details', {
            url: '/details/{id:[0-9]+}',
            templateUrl: 'public/templates/frontend/pages/details.html',
            controller: 'detailsController',
            data: {
                authenticated: false
            },
        })
         .state('product_management',{

            url: '/product_management',
            templateUrl: 'public/templates/frontend/users/product_management.html',
            controller: 'userController',
            data:{
                authenticated: true,
                }
        })
         
         /* Cutomer ticket flow states */
         .state('customerTicketLogin',{

            url: '/customerTicketLogin',
            templateUrl: 'public/templates/frontend/CustomerTicket/CustomerTicketLogin.html',
            controller: 'ticketController',
            data:{
                authenticated: false,
                }
        })
         .state('ticketGuestUser',{

            url: '/ticketGuestUser',
            templateUrl: 'public/templates/frontend/CustomerTicket/ticketGuestUser.html',
            controller: 'ticketController',
            data:{
                authenticated: false,
                }
        })
         .state('customerTicket',{

            url: '/customerTicket',
            templateUrl: 'public/templates/frontend/CustomerTicket/customerTicket.html',
            controller: 'ticketController',
            data:{
                authenticated: true,
                }
        })
         .state('guestUserDetail',{

            url: '/guestUserDetail/:id',
            templateUrl: 'public/templates/frontend/CustomerTicket/guestUserDetail.html',
            controller: 'ticketController',
            data:{
                authenticated: false,
                }
        })
        .state('addCustomerTicket',{

            url: '/addCustomerTicket',
            templateUrl: 'public/templates/frontend/CustomerTicket/addCustomerTicket.html',
            controller: 'ticketController',
            data:{
                authenticated: true,
                }
        })
        .state('viewTicketDetail',{

            url: '/viewTicketDetail/:id',
            templateUrl: 'public/templates/frontend/CustomerTicket/viewTicketDetail.html',
            controller: 'ticketController',
            data:{
                authenticated: true,
                }
        })
        .state('editTicketDetail',{

            url: '/editTicketDetail/:id',
            templateUrl: 'public/templates/frontend/CustomerTicket/editCustomerTicket.html',
            controller: 'ticketController',
            data:{
                authenticated: true,
                }
        })
         .state('mini_statement', {
             url: '/mini_statement/:acc_no/:app_id',
            templateUrl: 'public/templates/frontend/users/mini_statement.html',
            controller: 'globalController',
            data:{
                authenticated: true,
                }
        })
        .state('upload_sanction_letter', {
             url: '/upload_sanction_letter/:app_no',
            templateUrl: 'public/templates/frontend/users/upload_sanction_letter.html',
            controller: 'globalController',
            data:{
                authenticated: true,
                }
        })
        .state('product_details', {
             url: '/product_details/:product_id',
            templateUrl: 'public/templates/frontend/users/product_details.html',
            controller: 'globalController',
            data:{
                authenticated: true,
                }
        });
          

 });
